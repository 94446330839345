<template>
    <div class="row mt-3">
        <div class="col-6 mb-3">
            <button class="btn btn-light btn-sm" :disabled="previousPageDisabled" @click="previousPage(); getAssignmentsList()">
                <b-icon icon="chevron-left"></b-icon>
            </button>
            <small class="mb-0">{{pagerConfiguration.page}} / {{pagerConfiguration.pageCount}}</small>
            <b-button variant="light" class="btn-sm" :disabled="nextPageDisabled" @click="nextPage(); getAssignmentsList()">
                <b-icon icon="chevron-right"></b-icon>
            </b-button>
        </div>
        <div class="col-6 text-right">
            <small class="mb-0 text-capitalize">{{pagerConfiguration.current}} Registros de un total {{pagerConfiguration.count}}</small>
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
    export default {

        data: function () {
            return {
                minimumNumberOfPages: 1
            }
        },
        methods: {
            ...mapActions('assignments', ['getAssignmentsList']),
            ...mapMutations('assignments', ['nextPage', 'previousPage'])
        },
        computed: {
            ...mapState('assignments', ['showPendingOn', 'pagerConfiguration']),
            nextPageDisabled: function () {
                if(this.showPendingOn){
                    return true;
                }

                return this.pagerConfiguration.pageCount == this.pagerConfiguration.page
            },
            previousPageDisabled: function () {
                if(this.showPendingOn){
                    return true;
                }

                return this.pagerConfiguration.page == this.minimumNumberOfPages;
            }
        }
    }
</script>
<template>
    <div class="row border-bottom mt-3">
        <div class="col-md-3 mb-3">
            <p class="mb-0"><b-icon icon="circle-fill" variant="secondary"></b-icon> Pendiente: <b>{{totalesPendinetes.pendiente}}</b></p>
        </div>

        <div class="col-md-3 mb-3">
            <p class="mb-0"><b-icon icon="circle-fill" variant="primary"></b-icon> Procesando: <b>{{totalesPendinetes.procesando}}</b></p>
        </div>

        <div class="col-md-3 mb-3">
            <p class="mb-0"><b-icon icon="circle-fill" variant="warning"></b-icon> En Correción: <b>{{totalesPendinetes.pendiente_por_corregir}}</b></p>
        </div>

        <div class="col-md-3 mb-3">
            <p class="mb-0"><b-icon icon="circle-fill" variant="success"></b-icon> En Espera: <b>{{totalesPendinetes.en_espera}}</b></p>
        </div>                                                  
    </div>
</template>

<script>
import { mapState } from 'vuex'
    export default {
        computed: {
            ...mapState('assignments', ['totalesPendinetes'])
        }
    }
</script>

<style lang="scss" scoped>

</style>
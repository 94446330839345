<template>
<div class="row border-bottom bg-white" :style="{'height': heightContent + 'px'}">
    <div class="col-12 px-0 table-responsive mb-0" style="height: 100%">
        <table class="table table-sm">
            <thead>
            <tr>
                <th class="pl-3">
                <div class="form-check">
                    <input class="form-check-input position-static" type="checkbox" id="blankCheckbox" value="option1" aria-label="...">
                </div>
                </th>
                <th>ID</th>
                <th>Recarga</th>
                <th>Estado</th>
                <th></th>
            </tr>
            </thead>
            <tbody class="border-bottom">
                <tr v-for="(assignment, id) in assignmentsList" :key="id" :id="`table-row-${assignment.id}`">
                    <td class="pl-3">
                    <div class="form-check">
                        <input class="form-check-input position-static" type="checkbox" id="blankCheckbox" value="option1" aria-label="...">
                    </div>
                    </td>
                    <td v-text="'#' + assignment.id"></td>
                    <td><strong v-text="assignment.recarga.numero"></strong></td>
                    <td><badge :rechargeStatus="assignment.recarga.estado"></badge></td>
                    <td><router-link :to="`/assignments/view/${assignment.id}`">Ver</router-link></td>
                </tr>                                                                                                                                                                                                                                
            </tbody>
        </table>
    </div>
</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Badge from '../../components/Badges.vue'

    export default {
        components: {
            Badge
        },
        data: function () {
            return {
                estado: {
                    'pendiente' : 'badge badge-primary',
                    'procesando' : 'badge badge-info',
                    'pendiente_por_corregir' : 'badge badge-warning',
                    'ejecutada' : 'badge badge-secondary',
                    'suspendida' : 'badge badge-danger',
                    'en_espera' : 'badge badge-success',
                }
            }
        },
        created: function () {
            this.getAssignmentsList();
        },
        computed: {
            ...mapState('assignments', ['assignmentsList', 'showPendingOn']),
            heightContent: function () {
                return this.showPendingOn ? 345 : 400;
            },            
        },
        methods: {
            ...mapActions('assignments',['getAssignmentsList'])
        },
    }
</script>
<template>
    <div class="row border-bottom">
        <div class="col-md-5 mb-3">
            <b-form-select :options="getServices" @change="selectedServiceChange($event)" :value="selectedService"></b-form-select>
        </div>
        <div class="col-md-4 mb-3">
            <b-form-select :options="getRechargeStates" @change="selectedRechargeStatusChange($event)" :value="selectedRechargeState"></b-form-select>
        </div>
        <div class="col-md-3 mb-3">
            <b-form-select :options="bulkAssignmentActions" :value="null"></b-form-select>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
    export default {
        data: function () {
            return {
                minimumNumberOfPages: 1
            }
        },
        computed: {
            ...mapState('assignments', ['selectedService', 'selectedRechargeState', 'bulkAssignmentActions']),
            ...mapGetters('assignments', ['getServices', 'getRechargeStates'])
        },
        methods: {
            ...mapActions('assignments', ['getAssignmentsFilters', 'getAssignmentsList']),
            ...mapMutations('assignments', ['setSelectedService', 'setSelectedRechargeState', 'setSelectedPage']),
            selectedServiceChange: function(serviceID){
                this.setSelectedPage( this.minimumNumberOfPages );
                this.setSelectedService(serviceID);
                this.getAssignmentsList();
            },
            selectedRechargeStatusChange(state){
                this.setSelectedPage( this.minimumNumberOfPages );
                this.setSelectedRechargeState(state);
                this.getAssignmentsList();
            }
        },
        created: function () {
            this.getAssignmentsFilters();
        }
    }
</script>

<style lang="scss" scoped>

</style>
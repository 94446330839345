<template>
  <div class="row">
      <div class="col-12 mb-3">
        <h4>Mis Asignaciones</h4>
      </div>
      <div class="col-md-12">
        <div class="card">
          <card-header-index></card-header-index>
            <div class="card-body p-0">
                <div class="row">
                  <div class="col-md-8 pr-0">
                    <div class="bg-light px-3 pt-3 border-right">
                      <filter-index></filter-index>
                      <assignment-info-bar-index v-if="showPendingOn"></assignment-info-bar-index>
                      <assignments-list-index></assignments-list-index>
                      <assignment-list-pager-index></assignment-list-pager-index>
                    </div>
                  </div>
                  <div class="col-md-4">
                        <div v-if="$route.name == 'assignments'" class="d-flex align-items-center text-center" style="height:100%">
                          <h4>No hay asignaciones selecciondas</h4>
                        </div>
                        <router-view v-else></router-view>
                  </div>
                </div>
            </div>
        </div>          
      </div>
  </div>
</template>

<script>
import CardHeaderIndex from '../../components/Assignments/CardHeaderIndex.vue'
import FilterIndex from '../../components/Assignments/FilterIndex.vue'
import AssignmentInfoBarIndex from '../../components/Assignments/AssignmentInfoBarIndex.vue'
import AssignmentsListIndex from '../../components/Assignments/AssignmentListIndex.vue'
import AssignmentListPagerIndex from '../../components/Assignments/AssignmentListPagerIndex.vue'

import { mapActions, mapState } from 'vuex'


 
export default {
  components: {
    CardHeaderIndex, 
    FilterIndex, 
    AssignmentInfoBarIndex, 
    AssignmentsListIndex, 
    AssignmentListPagerIndex,
    Notification
  },
  data: function () {
    return { };
  },
  computed: {
    ...mapState('assignments', ['showPendingOn', 'selectedAssignment', 'unanouncedAssignments'])
  },
  methods: {
    ...mapActions('assignments', ['getFeedbacks', 'getPlatforms', 'getAssignmentsList', 'getPendingAssignments'])
  },
  watch: {
    $route(to, from) {
      if(to.name == 'assignments'){
        this.getAssignmentsList();
      }
    }
  },
  created: function () {
    this.getFeedbacks();
    this.getPlatforms();
  },
  updated: function() {
      if(this.$route.name == 'assignments'){
        for (let item of document.querySelectorAll('.table-primary')) {
            item.classList.remove('table-primary');
        }
      }
  }
};
</script>

<style scoped>
  div.card-body .bg-light {
    background-color: rgba(0, 0, 0, 0.03) !important;
  }
</style>
<template>
    <div class="card-header">
        <div class="row">
            <div class="col-md-5">
                <form @submit.prevent="showCloseButton = true; setSelectedPage(1); getAssignmentsList()">
                    <div class="input-group">
                        <input type="text" @input="updateQuery($event)" :value="query" class="form-control" placeholder="Numero de recarga ó ID de asignación">
                        <div class="input-group-append">
                            <button 
                                class="btn btn-danger" 
                                v-if="showCloseButton"
                                @click="showCloseButton = false; setQuery(''); setSelectedPage(1); getAssignmentsList()"
                                type="button">
                                <b-icon icon="x-circle-fill"></b-icon>
                            </button>
                            <button class="btn btn-outline-secondary" type="submit" id="button-addon2"><b-icon icon="search"></b-icon></button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-md-3 text-center">
                <b-spinner v-if="mainLoader"></b-spinner>
            </div>
            <div class="col-md-4">
                <button :class="[hasAssignments ? 'btn-primary': 'btn-outline-primary']" class="btn float-right ml-3" @click="updateList()">
                    <b-icon icon="bell" :animation="animating"></b-icon>
                    <b-badge variant="light">{{unanouncedAssignments.length}}</b-badge>
                </button>                
                <button class="btn float-right" :class="[showPendingOn ? 'btn-primary': 'btn-outline-primary']" @click="getPendingAssignments()">{{ showPendingOn ? 'Mostrar Todas':'Mostrar solo pendientes' }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
    export default {
        data: function(){
            return {
                minimumNumberOfPages: 1,
                showCloseButton: false
            }
        },
        methods: {
            ...mapMutations('assignments', [
                'setQuery', 
                'setShowPendingOn', 
                'setSelectedPage', 
                'setSelectedService', 
                'setSelectedRechargeState', 
                'setUnanouncedAssignments'
            ]),
            ...mapActions('assignments', ['getAssignmentsList']),
            updateQuery: function (event) {
                this.setQuery(event.target.value);
            },
            getPendingAssignments: function(){
                this.setSelectedService( null )
                this.setSelectedRechargeState( null )
                this.setSelectedPage( this.minimumNumberOfPages );
                this.setShowPendingOn( !this.showPendingOn );
                this.getAssignmentsList();
            },
            updateList: function(){
                this.setUnanouncedAssignments([]);
                this.setShowPendingOn(true);
                this.getAssignmentsList();
            }
        },
        computed: {
            ...mapState('assignments', [
                'mainLoader', 
                'showPendingOn', 
                'query',
                'unanouncedAssignments'
            ]),
            hasAssignments: function(){
                return this.unanouncedAssignments.length > 0;
            },
            animating: function () {
                return (this.hasAssignments) ? 'cylon':''
            }
        }
    }
</script>